import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from 'src/util/mobx/generic-store/generic.store'
import { createStore } from 'src/util/mobx/generic-store/store-creator'
import { downloadReport, getDepartmentCourses, getSkillGapOverview, getTeamCourseOverview, getTeamOverview, getUserCourses, getUserOverview, getUserStatus } from '../api/dashboard'
import { skillGapMetrics, SkillGapOverview, TeamCourseOverview, TeamMetrics, TeamOverview, UserCourses, UserMetrics, UserOverview, UserStatus } from '../model/dashboard-model'
import { getAllCourses } from 'src/feature/e-learning/api/e-learning'
import { CourseListItem } from 'src/feature/e-learning/model/ELearningCourseListItem'

export class dashboardStore extends GenericStore {
  teamCourseOverview: TeamCourseOverview | undefined
  teamOverview: TeamOverview = {}
  userOverview: UserOverview = {}
  departmentAssignedCourses: CourseListItem[] = []
  skillGapOverview: SkillGapOverview = {}
  userCourses: UserCourses | undefined
  userStatus: UserStatus[] | undefined

  constructor() {
    super('dashboardStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  reset() {
    this.teamCourseOverview = undefined
    this.teamOverview = {}
    this.userOverview = {}
    this.departmentAssignedCourses = []
    this.skillGapOverview = {}
    this.userCourses = undefined
    this.userStatus = undefined
  }

  @GenericStoreAsyncMethod()
  async getTeamCourseOverview() {
    const data: TeamCourseOverview = await getTeamCourseOverview()
    this.teamCourseOverview = data
  }

  @GenericStoreAsyncMethod()
  async getTeamOverview(levelId: string) {
    const data: TeamMetrics = await getTeamOverview(levelId)
    this.teamOverview[levelId] = data
  }

  @GenericStoreAsyncMethod()
  async getRandomCategoryCourses(categoryTitle: string) {
    const data = await getAllCourses(categoryTitle, 0, 6)
    
    return data.items
  }

  @GenericStoreAsyncMethod()
  async getUserOverview(levelId: string, userId: string) {
    const data: UserMetrics = await getUserOverview(levelId, userId)
    this.userOverview[userId] = data
  }

  @GenericStoreAsyncMethod()
  async getDepartmentCourses(levelId: string) {
    const data: CourseListItem[] = await getDepartmentCourses(levelId)
    this.departmentAssignedCourses = data
  }

  @GenericStoreAsyncMethod()
  async getSkillGapOverview(levelId: string) {
    const data: skillGapMetrics = await getSkillGapOverview(levelId)
    this.skillGapOverview[levelId] = data
  }

  @GenericStoreAsyncMethod()
  async getUserCourses(userId: string) {
    const data: UserCourses = await getUserCourses(userId)
    this.userCourses = data
  }

  @GenericStoreAsyncMethod()
  async getUserStatus(userId?: string) {
    const data: UserStatus[] = await getUserStatus(userId)
    this.userStatus = data
  }

  @GenericStoreAsyncMethod()
  async downloadReport(levelId?: string) {
    const reportData = await downloadReport(levelId)
  
    const blob = new Blob([reportData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'report.xlsx'
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  }
}

export const {
  storeInstance: dashboardStoreInstance,
  useStore: useDashboardStore,
} = createStore(new dashboardStore())
