import { observer } from 'mobx-react-lite'
import { Autocomplete, Box, CircularProgress, circularProgressClasses, createFilterOptions, Fade, Typography } from '@mui/material'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { ProfessionType, SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { reaction } from 'mobx'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { ExpectedLevels } from './ExpectedLevels'
import { useNotificationStore } from 'src/feature/notification/store/notification.store'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export type ExpectedRatingProps = {
  levelId: string
}

export const ExpectedRating = observer(({ levelId }: ExpectedRatingProps) => {
  const { translation, locale } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const [progressValues, setProgressValues] = useState<number[]>([])
  const skillAssessmentStore = useSkillAssessmentStore()
  const notificationStore = useNotificationStore()
  const { isLoading: isSkillcategory } = skillAssessmentStore.getGenericFieldsState('getSkillCategory')
  const { isLoading: isProfessionLoading } = skillAssessmentStore.getGenericFieldsState('getProfessions')
  const { isLoading: isAddSkillCategory, isSuccess } = skillAssessmentStore.getGenericFieldsState('addSkillCategory')
  const isLoading = isSkillcategory || isProfessionLoading || isAddSkillCategory
  const [profession, setProfession] = useState<ProfessionType | null>(null)
  const filter = createFilterOptions<ProfessionType>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [skillAssessmentSetIndex, setSkillAssessmentSetIndex] = useState<number>(0)

  useEffect(() => {
    skillAssessmentStore.professionsData = undefined
    skillAssessmentStore.skillCategory = undefined
    skillAssessmentStore.getProfessions()
    skillAssessmentStore.getSkillCategory(levelId)
    updateProgressValues()
    reaction(
      () => skillAssessmentStore.skillCategory && skillAssessmentStore.skillCategory?.skills.map(skill => skill.expected_set_level),
      updateProgressValues,
    )
  }, [skillAssessmentStore])

  const updateProgressValues = () => {
    if (skillAssessmentStore.skillCategory?.skills) {
      const initialProgressValues = skillAssessmentStore.skillCategory?.skills.map(
        skill => ((skill?.expected_set_level || 0) / 5) * 100,
      )
      setProgressValues(initialProgressValues)
    }
  }

  const handleProfessionChange = (_: any, newValue: ProfessionType | null) => {
    if (newValue && newValue.inputValue) {
      setProfession({
        id: newValue.id,
        title: newValue.inputValue,
      })
    } else {
      setProfession(newValue || null)
    }
  }

  const handleProfessionFilterChange = (options: Array<ProfessionType>, params: any) => {
    const filtered = filter(options, params)
    const { inputValue } = params
    const isExisting = options.some(option => inputValue === option.title)
    
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        id: `default-id-'${inputValue.toLowerCase()}'`,
        title: `Add '${inputValue}'`,
      })
    }

    return filtered
  }

  const handleProfessionSave = async () => {
    const titleRegex = /^.{1,50}$/
    
    if (profession && titleRegex.test(profession.title)) {
      await skillAssessmentStore.addSkillCategory(profession.title, levelId, locale)
      if (!isLoading || isSuccess) {
        notificationStore.enqueueInfo(translations.errorMsg.roleAdded())
        skillAssessmentStore.skillCategory = undefined
        skillAssessmentStore.getSkillCategory(levelId)
      } else {
        notificationStore.enqueueError(translation.util.unknownErrorOccurred())
      }
    } else {
      notificationStore.enqueueError(translations.errorMsg.roleError())
    }
  }

  const handleRateSkillSetClick = (selectedSkillSetIndex: number) => {
    setSkillAssessmentSetIndex(selectedSkillSetIndex)
    setIsModalOpen(true)
  }

  return (
    <AppBox sx={styles.root}>
      {isLoading && <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
        <AppProgressLine sx={{ width: '5rem' }} isShowing={isLoading} />
        <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{translations.loadingMsg()}</Typography>
      </AppBox>}
      {!isLoading && !skillAssessmentStore.skillCategory && <Box sx={styles.noSkillWrapper}>
        <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
          <Title1>{translations.setDemandHeader()}</Title1>
          <Typography
            sx={{ width: { xs: '100%', md: '85%', lg: '70%' }, textAlign: 'center', color: 'text.secondary', hyphens: 'auto', overflowWrap: 'break-word' }}
            lang={locale}
          >{translations.setDemandDesc()}</Typography>
        </AppBox>
        <AppBox sx={styles.professionSelect}>
          <Autocomplete
            sx={{
              width: { xs: '50%', md: '40%', lg: '30%' },
              '& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator': {
                color: 'text.primary',
              },
            }}
            value={profession}
            onChange={handleProfessionChange}
            filterOptions={handleProfessionFilterChange}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='profession'
            options={skillAssessmentStore.professionsData || []}
            getOptionLabel={option => option.title || ''}
            renderOption={(props, option) => (
              <li {...props}>
                {option.title}
              </li>
            )}
            renderInput={params => (
              <AppTextField {...params} placeholder={translations.setDemandPlaceholder()} />
            )}
          />
          <AppButton variant='contained' onClick={handleProfessionSave}>{translations.setDemandButton()}</AppButton>
        </AppBox>
      </Box>}
      {!isLoading && skillAssessmentStore.skillCategory && <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{translations.setRoleConfirm()}</Typography>}
      {!isLoading && skillAssessmentStore.skillCategory && <AppBox sx={styles.ratingWrapper}>
        {!isLoading && skillAssessmentStore.skillCategory && skillAssessmentStore.skillCategory?.skills?.length !== 0 && skillAssessmentStore.skillCategory?.skills?.map((skill: SkillSet, index: number) => (
          <Box sx={{ ...styles.progressWrapper, position: 'relative', display: 'inline-flex', width: '180px', height: '180px' }} key={skill.id}>
            <Fade in={true} timeout={600} mountOnEnter unmountOnExit>
              <CircularProgress variant="determinate" value={100} sx={{
                ...styles.circlePosition,
                position: 'absolute',
                color: 'background.default',
              }} size={180} thickness={4} />
            </Fade>
            <Fade in={true} timeout={700} mountOnEnter unmountOnExit>
              <CircularProgress variant="determinate" sx={{
                color: 'alternate.primary',
                animationDuration: '600ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }} size={180} thickness={4} value={progressValues[index]} />
            </Fade>
            <Fade in={true} timeout={800} mountOnEnter unmountOnExit>
              <Box
                sx={{
                  ...styles.circlePosition,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Caption
                  variant="caption"
                  component="div"
                  color="text.primary"
                  sx={{ hyphens: 'auto', overflowWrap: 'break-word', fontWeight: '500' }}
                  lang={locale}
                >
                  <AppBox sx={styles.circleContainer}>
                    <Typography sx={styles.wordWrapper}>{skill.setName}</Typography>
                    <AppButton sx={styles.buttonContainer} onClick={() => handleRateSkillSetClick(index)}>{progressValues[index] === 0 ? translations.rolesButton.rateSkill() : translations.rolesButton.updateSkill()}</AppButton>
                  </AppBox>
                </Caption>
              </Box>
            </Fade>
          </Box>
        ))}
      </AppBox>}
      {!isLoading && skillAssessmentStore.skillCategory?.skills && <ExpectedLevels skillAssessmentSetIndex={skillAssessmentSetIndex} isModalOpen={isModalOpen} isModalClose={() => setIsModalOpen(false)} />}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
  },
  noSkillWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  progressWrapper: {
    transition: 'transform 0.3s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '3rem',
  },
  circleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '9em',
    gap: 7,
  },
  circlePosition: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  wordWrapper: {
    textAlign: 'center',
    wordWrap: 'break-word',
    fontWeight: '500',
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: Palette.BRIGHT_GREY_2,
    color: Palette.BLACK_OUT,
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: '400',
    borderColor: Palette.BRIGHT_GREY_2,
    transition: 'transform 0.3s ease, background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: 'alternate.primary',
      color: 'alternate.text',
      transform: 'scale(1.1)',
    },
  },
  professionSelect: {
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
  },
}