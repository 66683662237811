import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { Word } from '../../../localization/component/atom/Word'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const RecommendedCourses = observer(() => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning
  
  useEffect(() => {
    eLearningStore.getRecommendedCourses()
  }, [])

  return (
    <AppBox sx={styles.root}>
      <AppBox sx={styles.titleContainer}>
        <Tooltip title={texts.courseRecommendationInfo()}><InfoOutlinedIcon sx={{ color: 'secondary.main' }} /></Tooltip>
        <Title2 m={0}>{texts.recTitle()}</Title2>
        <Word sx={styles.pill}>{eLearningStore.recommendedCourses?.length || '0'}</Word>
      </AppBox>
      {eLearningStore.recommendedCourses && eLearningStore.recommendedCourses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
        {eLearningStore.recommendedCourses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
      </AppCarousel>}
      {eLearningStore.recommendedCourses && eLearningStore.recommendedCourses.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
    </AppBox>
  )
})

const styles = {
  root: (theme: Theme) => ({
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${theme.palette.alternate.background}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    margin: { xs: '0 2rem', md: '0 6rem', lg: '0 10rem' },
    gap: 5,
  }),
  titleContainer: {
    gap: 10,
    alignItems: 'center',
  },
  pill: {
    borderRadius: '1rem',
    border: '1px solid',
    borderColor: 'text.primary',
    padding: '0px 12px',
    backgroundColor: 'background.default',
    color: 'text.primary',
  },
}
