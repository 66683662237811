import { observer } from 'mobx-react-lite'
import Confetti from 'react-confetti'

export type AppConfettiProps = {
  show: boolean
}

export const AppConfetti = observer((props: AppConfettiProps) => {
  const width = window.innerWidth - 20
  const height = window.innerHeight

  return (
    <Confetti
      width={width}
      height={height}
      recycle={false}
      run={props.show}
    />
  )
})
