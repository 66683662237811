import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { CourseListPresentationItem } from 'src/feature/e-learning/component/organism/CourseListPresentationItem'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useDashboardStore } from '../store/dashboard.store'
import { CourseListItem } from 'src/feature/e-learning/model/ELearningCourseListItem'
import { Word } from 'src/feature/localization/component/atom/Word'
import { Theme } from '@mui/material/styles'

export type RecommendedCategoryCoursesProps = {
  category: string
}

export const RecommendedCategoryCourses = observer((props: RecommendedCategoryCoursesProps) => {
  const dashboardStore = useDashboardStore()
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const [courses, setCourses] = useState<CourseListItem[]>([])
  const texts = translation.pages.eLearning
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  
  useEffect(() => {
    getRandomCategoryCourses(props.category)
  }, [])

  const getRandomCategoryCourses = async (categoryTitle: string) => {
    const data = await dashboardStore.getRandomCategoryCourses(categoryTitle)
    setCourses(data)
  }

  return (
    <Box sx={styles.root}>
      <AppBox sx={styles.container}>
        <AppBox sx={{ alignItems: 'center' }}>
          <Title2 m={0}>{categories[props.category as ObjectKey].title()}</Title2>
          <Word sx={styles.pill}>{courses?.length || '0'}</Word>
        </AppBox>
        {courses && courses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {courses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
        </AppCarousel>}
        {courses && courses.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
        {courses && courses.length !== 0 && <AppBox sx={{ justifyContent: 'center' }}>
          <AppButton fontSize={15} variant='contained' size='small' onClick={() => navigate(appRoutePath.learningCategory(props.category))}>{translation.buttons.showMore()}</AppButton>
        </AppBox>}
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  container: (theme: Theme) => ({
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${theme.palette.alternate.background}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    gap: 5,
  }),
  pill: {
    marginLeft: '10px',
    borderRadius: '1rem',
    border: '1px solid',
    borderColor: 'text.primary',
    padding: '0px 12px',
    backgroundColor: 'background.default',
    color: 'text.primary',
  },
}
