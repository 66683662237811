import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { GetStarted } from '../component/GetStarted'
import { SkillGapAnalysis } from '../component/SkillGapAnalysis'
import { RecommendedCourses } from 'src/feature/e-learning/component/organism/RecommendedCourses'
import { CoursePlanPage } from 'src/feature/profile/page/CoursePlanPage'
import { RecommendedCategories } from '../component/RecommendedCategories'
import { useUserStore } from 'src/feature/user/store/user.store'

export const UserDashboardPage = observer(() => {
  const userStore = useUserStore()

  return (
    <Box sx={styles.root}>
      <GetStarted />
      {(userStore.sitemap?.modules ?? []).filter(s => s.key === 'teamDashboard' && s.level === 'proplus').length > 0 && <SkillGapAnalysis />}
      <CoursePlanPage />
      <RecommendedCourses />
      <RecommendedCategories />
    </Box>
  )
})

const styles = {
  root: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    padding: { xs: '1rem 0', md: '2rem 0' },
  },
}
