import { observer } from 'mobx-react-lite'
import { Box, CircularProgress, circularProgressClasses, Collapse, Fade, Typography } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useNotificationStore } from 'src/feature/notification/store/notification.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export const SkillGapAnalysis = observer(() => {
  const { translation, locale } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const skillAssessmentStore = useSkillAssessmentStore()
  const notificationStore = useNotificationStore()
  const navigate = useNavigate()
  const [showGaps, setShowGaps] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')

  useEffect(() => {
    skillAssessmentStore.skillSets = undefined
    skillAssessmentStore.getSkillSets()
  }, [])

  useEffect(() => {
    if (!isLoading && skillAssessmentStore.skillSets && skillAssessmentStore.skillSets?.skills?.length !== 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isLoading, skillAssessmentStore.skillSets?.skills?.length])

  const isAssessmentCompleted = () => {
    if (skillAssessmentStore.skillSets?.skills?.length !== 0) {
      const unCheckedSkills = skillAssessmentStore.skillSets?.skills?.filter(skill => skill.selectedSetLevel === null)
      const unCheckedExpectedSkills = skillAssessmentStore.skillSets?.skills?.filter(skill => skill.expected_set_level === 0)
      
      if (unCheckedSkills?.length === 0 && unCheckedExpectedSkills?.length === 0) {
        setShowGaps(!showGaps)
      } else if (unCheckedExpectedSkills?.length !== 0) {
        notificationStore.enqueueError(translations.errorMsg.noManagerRating())
      } else if (unCheckedSkills?.length !== 0) {
        notificationStore.enqueueError(translations.errorMsg.rateAllSkills(), {
          btnLabel: translations.rolesButton.rateSkill(),
          btnAction: () => navigate(appRoutePath.skillAssessment),
        })
      }
    }
  }

  const isAllSkillRated = () => {
    if (skillAssessmentStore.skillSets?.skills?.length !== 0) {
      const unCheckedSkills = skillAssessmentStore.skillSets?.skills?.filter(skill => skill.selectedSetLevel === null)
      
      return unCheckedSkills?.length === 0
    }
    
    return true
  }

  return (
    <Collapse in={open} timeout={400} mountOnEnter unmountOnExit>
      <Box sx={styles.root}>
        <AppBox sx={styles.ratingWrapper}>
          {skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
            <Box sx={{ ...styles.progressWrapper, position: 'relative', display: 'inline-flex', width: '205px', height: '205px' }} key={skill.id}>
              {/* Expected level ring */}
              <Fade in={showGaps} timeout={600} mountOnEnter unmountOnExit>
                <CircularProgress variant="determinate" value={100} sx={{
                  ...styles.circleExpectedPosition,
                  position: 'absolute',
                  color: 'background.default',
                }} size={205} thickness={2} />
              </Fade>
              <Fade in={showGaps} timeout={700} mountOnEnter unmountOnExit>
                <CircularProgress variant="determinate" sx={{
                  ...styles.circleExpectedPosition,
                  color: (skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) > (skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0) ? 'alternate.alternate' : 'alternate.secondary',
                  position: 'absolute',
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  },
                }} size={205} thickness={2} value={((skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) / 5) * 100} />
              </Fade>
              {/* Selected level ring */}
              <Fade in={open} timeout={600} mountOnEnter unmountOnExit>
                <CircularProgress variant="determinate" value={100} sx={{
                  ...styles.circleSelectedPosition,
                  position: 'absolute',
                  color: 'background.default',
                }} size={180} thickness={4} />
              </Fade>
              <Fade in={open} timeout={700} mountOnEnter unmountOnExit>
                <CircularProgress variant="determinate" sx={{
                  ...styles.circleSelectedPosition,
                  color: showGaps ? ((skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) > (skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0)) ? 'alternate.primary' : 'alternate.secondary' : 'alternate.primary',
                  position: 'absolute',
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  },
                }} size={180} thickness={4} value={((skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0) / 5) * 100} />
              </Fade>
              <Fade in={open} timeout={800} mountOnEnter unmountOnExit>
                <Box
                  sx={{
                    ...styles.circleExpectedPosition,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Caption
                    variant="caption"
                    component="div"
                    color="text.primary"
                    sx={{ fontWeight: '500', hyphens: 'auto', overflowWrap: 'break-word' }}
                    lang={locale}
                  >
                    <AppBox sx={styles.circleContainer}>
                      <Typography sx={styles.wordWrapper}>{skill.setName}</Typography>
                      <Fade in={showGaps} timeout={700} mountOnEnter unmountOnExit>
                        <Typography sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>{`${((skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0) / 5) * 100}% of ${((skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) / 5) * 100}%`}</Typography>
                      </Fade>
                    </AppBox>
                  </Caption>
                </Box>
              </Fade>
            </Box>
          ))}
        </AppBox>
        <Fade in={isAllSkillRated()} timeout={400} mountOnEnter unmountOnExit>
          <Typography sx={{ width: { xs: '100%', md: '75%', lg: '60%' }, textAlign: 'center', color: 'text.secondary' }}>{translation.pages.myGrowth.skillGapAnalysisFinished()}</Typography>
        </Fade>
        <AppBox sx={styles.buttonWrapper}>
          <AppButton fontSize={17} size='small' variant='light' color="primary" onClick={isAssessmentCompleted}>{!showGaps ? translations.rolesButton.showGaps() : translations.rolesButton.hideGaps()}</AppButton>
          <AppButton fontSize={17} size='small' variant='contained' color="primary" onClick={() => navigate(appRoutePath.skillAssessment)}>{isAllSkillRated() ? translations.rolesButton.updateSkill() : translations.rolesButton.rateSkill()}</AppButton>
        </AppBox>
      </Box>
    </Collapse>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3rem',
    padding: { xs: '0 1rem', sm: '0 2rem', lg: '0 3rem' },
  },
  progressWrapper: {
    transition: 'transform 0.3s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '3rem',
  },
  circleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '9em',
  },
  circleExpectedPosition: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  circleSelectedPosition: {
    top: 12,
    left: 12,
    bottom: 12,
    right: 12,
  },
  wordWrapper: {
    textAlign: 'center',
    wordWrap: 'break-word',
    fontWeight: '500',
    width: '100%',
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' },
    gap: 30,
    width: { xs: '90%', md: '50%', lg: '30%' },
  },
}