import { useLocalizationStore } from '../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from 'src/feature/e-learning/store/e-learning.store'
import { useEffect } from 'react'
import { CoursePlanList } from 'src/feature/e-learning/component/organism/CoursePlanList'
import { Box } from '@mui/material'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'

export const CoursePlanPage = observer(() => {
  const { translation } = useLocalizationStore()
  const { coursePlan } = translation.pages
  const eLearningStore = useELearningStore()
  const { isLoading } = eLearningStore.getGenericFieldsState('updatePersonalCoursePlan')
  
  useEffect(() => {
    eLearningStore.updatePersonalCoursePlan()
  }, [])

  return (
    <Box sx={styles.root}>
      <AppProgressLine isLoading={isLoading} />
      {!isLoading && eLearningStore.personalCourcePlan && <>
        <CoursePlanList title={coursePlan.assignedCourses()} items={eLearningStore.personalCourcePlan.filter(p => !p.isUserSelected)} />
        <CoursePlanList title={coursePlan.selectedCourses()} items={eLearningStore.personalCourcePlan.filter(p => p.isUserSelected)} />
        <CoursePlanList title={coursePlan.startedCourses()} items={eLearningStore.personalCourcePlan.filter(p => p.progress && !p.progress.completed)} />
        <CoursePlanList title={coursePlan.finishedCourses()} items={eLearningStore.personalCourcePlan.filter(p => p.progress && p.progress.completed)} />
      </>}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: { xs: '0 2rem', md: '0 6rem', lg: '0 10rem' },
    gap: 20,
  },
}
