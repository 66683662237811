import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

export type MetricWithValueProps = {
  label: string,
  value: number | string,
  size?: 'small' | 'large',
  color?: string,
}

export const MetricWithValue = observer((props: MetricWithValueProps) => {
  return (
    <Box key={props.label} sx={styles.root}>
      <Typography sx={{
        fontSize: props.size === 'large' ? { xs: '3em', md: '3em' } : { xs: '2em', md: '2em' },
        fontWeight: props.size === 'large' ? 700 : 100,
        color: props.color ? props.color : 'text.primary',
        textAlign: 'center',
      }}>{props.value}</Typography>
      <Typography sx={{ textAlign: 'center' }}>{props.label}</Typography>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '8em',
  },
}
