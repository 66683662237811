import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useDashboardStore } from '../store/dashboard.store'
import { Box, Typography } from '@mui/material'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { CourseListPresentationItem } from 'src/feature/e-learning/component/organism/CourseListPresentationItem'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Word } from 'src/feature/localization/component/atom/Word'
import { Theme } from '@mui/material/styles'

export type DepartmentCoursesProps = {
  levelId: string
}

export const DepartmentAssignedCourses = observer(({ levelId }: DepartmentCoursesProps) => {
  const dashboardStore = useDashboardStore()
  const navigate = useNavigate()
  const { translation } = useLocalizationStore()
  const { isLoading } = dashboardStore.getGenericFieldsState('getDepartmentCourses')
  const translations = translation.pages.myTeam
  
  useEffect(() => {
    dashboardStore.getDepartmentCourses(levelId)
  }, [])

  return (
    <Box sx={styles.root}>
      <AppBox sx={{ flexDirection: 'column', gap: 10, padding: { xs: '0 1rem', md: '0 3rem', maxWidth: '100%' } }}>
        <AppBox sx={styles.courseContainer}>
          <AppBox sx={{ alignItems: 'center' }}>
            <Title2 m={0}>{`${translations.assignedToDepartment()} ${dashboardStore.teamOverview?.[levelId]?.organisationLevelName}`}</Title2>
            <Word sx={styles.pill}>{dashboardStore.departmentAssignedCourses?.length || '0'}</Word>
          </AppBox>
          {!isLoading && dashboardStore.departmentAssignedCourses && dashboardStore.departmentAssignedCourses?.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
            {dashboardStore.departmentAssignedCourses?.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
          </AppCarousel>}
          {dashboardStore.departmentAssignedCourses && dashboardStore.departmentAssignedCourses?.length === 0 && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', color: 'text.secondary', gap: 5 }}>
            <Caption sx={{ color: 'text.secondary' }}>{translations.errorMsg.noAssignedCourses()}</Caption>
            <Caption sx={{ fontWeight: 500, cursor: 'pointer', color: 'secondary.main' }} onClick={() => navigate(appRoutePath.learning)}>{translation.pages.myTeam.redirectionMessage()}</Caption>
          </AppBox>}
        </AppBox>
      </AppBox>
      <AppPaper sx={styles.bannerWrapper}>
        <Typography sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>{translations.needMoreCoursesHeader()}</Typography>
        <Typography>{translations.needMoreCoursesDesc()}</Typography>
        <AppButton sx={ styles.buttonContainer } onClick={() => navigate(appRoutePath.learning)}>{translations.assignMoreCoursesButton()}</AppButton>
      </AppPaper>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4rem',
    width: '100%',
  },
  bannerWrapper: {
    padding: { xs: '2rem', md: '5rem' },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    width: '100%',
    color: 'alternate.light',
    backgroundColor: 'alternate.dark',
  },
  buttonContainer: {
    fontSize: '1em',
    background: 'unset',
    color: 'alternate.light',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
      color: 'alternate.light',
      background: 'unset',
    },
  },
  courseContainer: (theme: Theme) => ({
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${theme.palette.alternate.background}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    gap: 5,
  }),
  pill: {
    marginLeft: '10px',
    borderRadius: '1rem',
    border: '1px solid',
    borderColor: 'text.primary',
    padding: '0px 12px',
    backgroundColor: 'background.default',
    color: 'text.primary',
  },
}
