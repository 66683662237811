import { axiosInstance } from '../../../util/axios/instance.axios'
import { SiteMap } from 'src/feature/user/model/SiteMap'
import { OrganisationUser } from '../model/OrganisationUser'
import { SystemSettings } from '../model/SystemSettings'
import { OrganisationInvite } from '../model/OrganisationInvite'
import { OrganisationRole } from '../model/OrganisationRole'

const ADMIN_API_PREFIX = '/b2b/admin/'

export function getAdminApiUrl(path: string) {
  return ADMIN_API_PREFIX + path
}

export const saveSiteMap = async (data: SiteMap): Promise<void> => {
  await axiosInstance.request({
    url: getAdminApiUrl('sitemap'),
    method: 'post',
    data,
  })
}

export const getUsers = async (): Promise<OrganisationUser[]> => {
  const response = await axiosInstance.request<OrganisationUser[]>({
    url: getAdminApiUrl('users'),
    method: 'get',
  })

  return response.data
}

export const getInvites = async (): Promise<OrganisationInvite[]> => {
  const response = await axiosInstance.request<OrganisationInvite[]>({
    url: getAdminApiUrl('invite-user'),
    method: 'get',
  })

  return response.data
}

export const postUserInvite = async (emails: string, groupKeys: string[], globalRole: OrganisationRole): Promise<void> => {
  await axiosInstance.request({
    url: getAdminApiUrl('invite-user'),
    data: { emails, groupKeys, globalRole },
    method: 'post',
  })
}

export const postAddUser = async (emails: string, groupKeys: string[], globalRole: OrganisationRole): Promise<void> => {
  await axiosInstance.request({
    url: getAdminApiUrl('add-user'),
    data: { emails, groupKeys, globalRole },
    method: 'post',
  })
}

export const postOrganisationUser = async (userId: string, groupKeys: string[], globalRole: OrganisationRole): Promise<void> => {
  await axiosInstance.request({
    url: getAdminApiUrl(`organisation-user/${userId}`),
    data: { groups: groupKeys, globalRole },
    method: 'post',
  })
}

export const deleteOrganisationUser = async (userId: string): Promise<void> => {
  await axiosInstance.request({
    url: getAdminApiUrl(`organisation-user/${userId}`),
    method: 'delete',
  })
}

export const deleteUserInvite = async (inviteId: string) => {
  await axiosInstance.request({
    url: getAdminApiUrl(`invite-user/${inviteId}`),
    method: 'delete',
  })
}

export const getSystemSettings = async (): Promise<SystemSettings> => {
  const response = await axiosInstance.request<SystemSettings>({
    url: getAdminApiUrl('system-settings'),
    method: 'get',
  })

  return response.data
}

export const postSystemSettings = async (systemSettings: SystemSettings): Promise<void> => {
  await axiosInstance.request({
    url: getAdminApiUrl('system-settings'),
    method: 'post',
    data: systemSettings,
  })
}