import { axiosInstance } from 'src/util/axios/instance.axios'
import { skillGapMetrics, TeamCourseOverview, TeamMetrics, UserCourses, UserMetrics, UserStatus } from '../model/dashboard-model'
import { CourseListItem } from 'src/feature/e-learning/model/ELearningCourseListItem'

export async function getTeamCourseOverview(): Promise<TeamCourseOverview> {
  const { data } = await axiosInstance.request<TeamCourseOverview>({
    url: `/dashboard/teamcourse-overview`,
  })

  return data
}

export async function getTeamOverview(levelId: string): Promise<TeamMetrics> {
  const { data } = await axiosInstance.request<TeamMetrics>({
    url: `/dashboard/team-overview/${levelId}`,
  })

  return data
}

export async function getUserOverview(levelId: string, userId: string): Promise<UserMetrics> {
  const { data } = await axiosInstance.request<UserMetrics>({
    url: `/dashboard/user-overview/${levelId}/${userId}`,
  })

  return data
}

export async function getDepartmentCourses(levelId: string): Promise<CourseListItem[]> {
  const { data } = await axiosInstance.request<CourseListItem[]>({
    url: `/dashboard/department-courses/${levelId}`,
  })

  return data
}

export async function getSkillGapOverview(levelId: string): Promise<skillGapMetrics> {
  const { data } = await axiosInstance.request<skillGapMetrics>({
    url: `/skill-assessment/skill-gap/${levelId}`,
  })

  return data
}

export async function getUserCourses(userId: string): Promise<UserCourses> {
  const { data } = await axiosInstance.request<UserCourses>({
    url: `/dashboard/user-courses/${userId}`,
  })

  return data
}

export async function getUserStatus(userId?: string): Promise<UserStatus[]> {
  const url = userId ? `/dashboard/user-status/${userId}` : '/dashboard/user-status'
  const { data } = await axiosInstance.request<UserStatus[]>({
    url,
  })

  return data
}

export async function downloadReport(levelId?: string): Promise<any> {
  const url = levelId ? `/dashboard/download-report/${levelId}` : '/dashboard/download-report/'
  const { data } = await axiosInstance.request<any>({
    url,
    responseType: 'arraybuffer',
  })

  return data
}
